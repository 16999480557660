import {inject, Injectable} from '@angular/core';

import {PlatformBrowserService} from '@core/modules';
import {ExtendedWindow, WINDOW} from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class GtmService {

  private readonly window = inject(WINDOW) as ExtendedWindow;
  private readonly platformBrowserService = inject(PlatformBrowserService);

  constructor() { }

  pushEvent(payload: {event: string, [key: string]: string}): void {
    if (this.platformBrowserService.isServer) {
      return;
    }
    this.window?.dataLayer?.push(payload);
  }
}
